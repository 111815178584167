<template>
  <section class="l-content"></section>
</template>
<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['userInfo'])
  },
  name: 'MettingRoom',
  components: {},
  data() {
    return {
      condition: {
        departId: '',
        userId: '',
        startDate: this.$lgh.getDate(-60),
        endDate: this.$lgh.getDate()
      },
      chartTypeList: [
        { code: 'Line', text: '折线图' },
        // { code: 'StepLine', text: '阶梯折图' },
        // { code: 'Area', text: '面积图' },
        // { code: 'StackedArea', text: '堆叠面积图' },
        { code: 'Column', text: '柱状图' },
        // { code: 'StackedColumn', text: '堆叠柱状图' },
        // { code: 'GroupedColumn', text: '分组柱状图' },
        // { code: 'RangeColumn', text: '区间柱状图' },
        // { code: 'Histogram', text: '直方图' },
        { code: 'Waterfall', text: '瀑布图' },
        { code: 'Bar', text: '条形图' }
        // { code: 'StackedBar', text: '堆叠条形图' },
        // { code: 'GroupedBar', text: '分组条形图' },
        // { code: 'RangeBar', text: '区间条形图' },
        // { code: 'Pie', text: '饼图' },
        // { code: 'Donut', text: '环图' },
        // { code: 'Rose', text: '玫瑰图' },
        // { code: 'StackedRose', text: '堆叠玫瑰图' },
        // { code: 'GroupedRose', text: '分组玫瑰图' },
        // { code: 'Radar', text: '雷达图' },
        // { code: 'Scatter', text: '散点图' },
        // { code: 'Bubble', text: '气泡图' },
        // { code: 'Heatmap', text: '热力图' },
        // { code: 'DensityHeatmap', text: '密度热力图' },
        // { code: 'Treemap', text: '矩形树图' },
        // { code: 'Calendar', text: '日历图' },
        // { code: 'Bullet', text: '子弹图' },
        // { code: 'Gauge', text: '仪表盘' },
        // { code: 'Liquid', text: '水波图' },
        // { code: 'WordClord', text: '词云' }
      ],
      chartType: 'Column',
      dataList: null,
      chartData: null,
      chartOption: {
        title: {
          visible: true,
          text: '分时用车数量统计'
        },
        meta: {
          label: {
            alias: '公司部门'
          },
          orderCount: {
            alias: '用车订单数量',
            formatter: v => {
              return `${v}单`
            }
          }
        },
        smooth: true,
        data: this.data,
        xField: 'label',
        yField: 'orderCount',
        xAxis: {
          title: {
            visible: false
          }
        }
      }
    }
  },
  created() {
    // this.getDataList()
  },
  methods: {
    dataRangeChange(d1, d2) {
      this.condition.startDate = d1
      this.condition.endDate = d2
    },
    async getDataList() {
      this.dataList = await this.$lgh.get('api/enterprise/statistic/evorder/chart', ['enterpriseCode', this.condition])
      this.chartData = this.dataList
    }
  }
}
</script>
<style scoped>
.el-main {
  padding: 0;
}
</style>
